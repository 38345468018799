const department =
    [
        {
            value: "BA Eco"
        },
        {
            value: "BA Eng"
        },
        {
            value: "BA WAS"
        },
        {
            value: "BBA"
        },
        {
            value: "BSc Biotech"
        },
        {
            value: "BSc Biochem"
        },
        {
            value: "BSc CS"
        },
        {
            value: "BSc Maths and Physics"
        },
        {
            value: "BSc Micro"
        },
        {
            value: "B.Com CA"
        },
        {
            value: "B.Com Co-op"
        },
        {
            value:"BVoc Islamic finance"
        },
        {
            value: "BVoc Logi"
        },
        {
            value: "BVoc Prof"
        },
        {
            value: "MA Eco"
        },
        {
            value: "MA Eng"
        },
        {
            value: "MA History"
        },
        {
            value: "MSc Micro"
        },
        {
            value: "M.com"
        },
    ]
export default department;