import React from "react";
import styles from "./styles.module.css";

function Hero() {
  return (
    <div className={styles.hero}>
      <h1>
        <span>Get ready</span>
      Let's dive into edebiyat
      </h1>
    </div>
  );
}

export default Hero;
